import React from 'react'
import { Link } from 'gatsby'

import colors from '../styles/colors'
import { device } from '../styles/media'

import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import styled, { css } from 'styled-components'

const StyledLink = styled(Link)`
  color: ${colors.textLight};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledContainer = styled(Container)`
  padding-bottom: 2rem;
  padding-top: 2rem;
`

const StyledGridItem = styled(Grid)`
  color: white;
  height: auto;
  line-height: 35px;
  max-height: 600px;
  padding-top: 5.0rem;

  ${props =>
    props.bottom &&
    css`
      padding-bottom: 2rem;
      text-align: center;

      @media ${device.mobile} {
        padding-bottom: 0;
      }
    `}

    @media ${device.tablet} {
      ${props =>
        props.upper_right &&
        css`
          padding-top: 0;
          @media ${device.xs} {
            padding-top: 0 !important;
          }
        `}
        
      ${props =>
        props.bottom &&
        css`
          padding-top: 0;
        `}

      ${props =>
        props.top &&
        css`
          text-align: center;
          @media ${device.xs} {
            box-sizing: border-box;
            padding-top: 2rem;
          }
        `}
`

const StyledGridConnectItem = styled(Grid)`
  color: white;
  height: auto;
  line-height: 35px;
  margin: 0 auto;
  max-height: 600px;
  padding-top: 5.0rem;
  text-align: center;

  ${props =>
    props.bottom &&
    css`
      padding-bottom: 2rem;
      text-align: center;

      @media ${device.mobile} {
        padding-bottom: 0;
      }
    `}

    @media ${device.tablet} {
      ${props =>
        props.upper_right &&
        css`
          padding-top: 0;
          @media ${device.xs} {
            padding-top: 0 !important;
          }
        `}
        
      ${props =>
        props.bottom &&
        css`
          padding-top: 0;
        `}

      ${props =>
        props.top &&
        css`
          text-align: center;
          @media ${device.xs} {
            box-sizing: border-box;
            padding-top: 2rem;
          }
        `}
`

const StyledH2 = styled.h2`
  margin: 0;
`

const MainShape = styled(Grid)`
  background-color: black;
  background-image: linear-gradient(${colors.footerLight}, ${colors.primaryDark});
  height: auto;
  margin: 0;
  min-height: 750px;
  width: 100vw;
`

const StyledP = styled.p`
  color: ${colors.textLight};
  margin-top: 0.5rem;
`

const SocialLink = styled.a`
  color: white;
  font-size: 24px;
  padding: 1rem;

  :hover {
    color: ${colors.secondary};
  }
`

const StyledGrid = styled(Grid)``

const Divider = styled(Grid)`
  height: 1px;
  margin-bottom: 25px;
  margin-top: 25px;
`

const Footer = () => {
  return (
    <StyledGrid container>
      <MainShape item>
        <Container>
          <Grid container>
            <StyledGridItem item md={6} sm={12} upper_right="true" top="true">
              <StyledContainer>
                <StyledH2>Surprisingly, we're human.</StyledH2>
                <StyledP>
                  And understanding your business needs are important to us. Email us to schedule an appointment or call
                  us directly; we're happy to talk.
                </StyledP>

                <StyledP>
                  <StyledLink to="/privacy-policy/apps">Privacy Policy for Apps</StyledLink>
                </StyledP>

                <StyledP>
                  <StyledLink to="/terms-of-use/apps">Terms of Use for Apps</StyledLink>
                </StyledP>
              </StyledContainer>
            </StyledGridItem>
          </Grid>

          <Divider />

          <Grid container>
            <StyledGridConnectItem bottom="true" item center>
              <SocialLink
                href="https://www.facebook.com/lamarsoftware"
                target="_blank"
                rel="noopener"
                aria-label="Find LaMar Software on Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} aria-hidden="true" />
              </SocialLink>

              <SocialLink
                href="https://www.instagram.com/lamarsoftware"
                target="_blank"
                rel="noopener"
                aria-label="Find LaMar Software on Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} aria-hidden="true" />
              </SocialLink>

              <SocialLink
                href="https://www.linkedin.com/company/lamarsoftware"
                target="_blank"
                rel="noopener"
                aria-label="Find LaMar Software on LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} aria-hidden="true" />
              </SocialLink>

              <StyledP>taylor@lamarsoftware.io</StyledP>
              <StyledP>(209) 322-7884</StyledP>
            </StyledGridConnectItem>
          </Grid>
        </Container>
      </MainShape>
    </StyledGrid>
  )
}

export default Footer
