const size = {
  xs: '500px',
  mobile: '600px',
  tablet: '960px',
  laptop: '1280px',
  desktop: '1920px',
}

export const device = {
  xs: `(max-width: ${size.xs})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
}
