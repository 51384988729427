import React from 'react'
import { fadeIn } from 'react-animations'
import { Link } from 'gatsby'

import logo from '../images/logo_lamar_white.png'

import colors from '../styles/colors'
import { device } from '../styles/media'

import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import styled, { keyframes } from 'styled-components'

const fadingAnimation = keyframes`${fadeIn}`

const HiddenLink = styled(Link)`
  color: white;
  cursor: pointer;
  padding: 1.2rem 12rem 1.2rem 2rem;
  text-decoration: none;
  transition: 0.25s;

  :hover {
    color: ${colors.secondary};
  }

  :not(:first-child) {
    border-top: 1px solid ${colors.textLight};
  }
`

const StyledHidden = styled.div`
  border-top: 1px solid ${colors.textLight};
  padding: 1.2rem 12rem 1.2rem 0;
  transition: 0.25s;
`

const HiddenSocialLink = styled.a`
  color: white;
  cursor: pointer;
  padding-left: 1rem;
  text-decoration: none;

  :hover {
    color: ${colors.secondary};
  }
`

const StyledLink = styled(Link)`
  color: white;
  padding: 1rem 2rem;
  float: left;
  text-decoration: none;
  line-height: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  transition: 0.25s;

  :hover {
    color: ${colors.secondary};
  }

  @media ${device.tablet} {
    display: none;
    padding-left: 1rem;
  }
`

const SocialLink = styled.a`
  color: white;
  padding: 1rem;
  float: left;
  text-decoration: none;
  line-height: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  transition: 0.25s;

  :hover {
    color: ${colors.secondary};
  }

  @media ${device.tablet} {
    display: none;
    padding-left: 1rem;
  }
`

const StyledBrandLink = styled(StyledLink)`
  display: inline-flex !important;
  font-size: 2rem;
  vertical-align: middle;
`

const StyledDrawer = styled(Drawer)`
  margin-left: 32px;

  & > .MuiPaper-root {
    background-color: ${colors.primaryLight};
  }
`

const StyledHiddenIcon = styled(FontAwesomeIcon)`
  color: white;
  cursor: pointer;
  display: none;
  float: left;
  font-size: 35px;
  font-weight: 400;
  line-height: 2.5rem;
  padding: 1rem 1.5rem !important;
  text-decoration: none;
  transition: 0.25s;

  :hover {
    color: ${colors.secondary};
  }

  @media ${device.tablet} {
    display: initial;
    padding: 0.1rem 1.2rem 0 0;
  }
`

const StyledImg = styled.img`
  height: 40px;
  width: 40px;
`

const StyledNavBar = styled(Grid)`
  background-color: ${colors.primaryLight};
  border-bottom: 1px solid rgba(250, 275, 270, 0.1);
  display: flex;
  height: 75px;
  padding-left: 32px;
  padding-right: 32px;
  position: fixed;
  right: 0;
  width: 100vw;
  z-index: 1;

  // animation: 2s ${fadingAnimation};

  @media(${device.tablet}) {
    justify-content: space-between;
  }
`

const StyledIcons = styled.div`
  padding-right: 10rem;
  position: fixed;
  right: 0;
`

const Header = () => {
  const [state, setState] = React.useState({
    open: false,
  })

  const toggleDrawer = () => {
    setState({
      open: !state.open,
    })
  }

  return (
    <Grid container>
      <StyledNavBar item>
        <StyledDrawer open={state.open} onClick={toggleDrawer}>
          <HiddenLink to="/">Home</HiddenLink>
          <HiddenLink to="/about">About Us</HiddenLink>
          <HiddenLink to="/case-studies">Case Studies</HiddenLink>
          <HiddenLink to="/contact">Contact Us</HiddenLink>
          {/* <HiddenLink to="/blog">Blog</HiddenLink> */}

          <StyledHidden>
            <HiddenSocialLink
              href="https://www.facebook.com/lamarsoftware"
              target="_blank"
              rel="noopener"
              social
              aria-label="Go to Facebook Page"
            >
              <FontAwesomeIcon icon={faFacebookF} aria-hidden="true" />
            </HiddenSocialLink>

            <HiddenSocialLink
              href="https://www.instagram.com/lamarsoftware"
              target="_blank"
              rel="noopener"
              social
              aria-label="Go to Instagram Page"
            >
              <FontAwesomeIcon icon={faInstagram} aria-hidden="true" />
            </HiddenSocialLink>

            <HiddenSocialLink
              href="https://www.linkedin.com/company/lamarsoftware"
              target="_blank"
              rel="noopener"
              social
              aria-label="Go to LinkedIn Page"
            >
              <FontAwesomeIcon icon={faLinkedinIn} aria-hidden="true" />
            </HiddenSocialLink>
          </StyledHidden>
        </StyledDrawer>

        <StyledBrandLink to="/">
          <StyledImg src={logo} alt="LaMar Software Logo" />
        </StyledBrandLink>
        <StyledHiddenIcon onClick={toggleDrawer} icon={faBars} />

        <StyledLink to="/about">About</StyledLink>
        <StyledLink to="/case-studies">Case Studies</StyledLink>
        <StyledLink to="/contact">Contact Us</StyledLink>
        {/* <StyledLink to="/blog">Blog</StyledLink> */}

        <StyledIcons>
          <SocialLink
            href="https://www.facebook.com/lamarsoftware"
            target="_blank"
            rel="noopener"
            social
            aria-label="Go to Facebook Page"
          >
            <FontAwesomeIcon icon={faFacebookF} aria-hidden="true" />
          </SocialLink>

          <SocialLink
            href="https://www.instagram.com/lamarsoftware"
            target="_blank"
            rel="noopener"
            social
            aria-label="Go to Instagram Page"
          >
            <FontAwesomeIcon icon={faInstagram} aria-hidden="true" />
          </SocialLink>

          <SocialLink
            href="https://www.linkedin.com/company/lamarsoftware"
            target="_blank"
            rel="noopener"
            social
            aria-label="Go to LinkedIn Page"
          >
            <FontAwesomeIcon icon={faLinkedinIn} aria-hidden="true" />
          </SocialLink>
        </StyledIcons>
      </StyledNavBar>
    </Grid>
  )
}

export default Header
